import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: linear-gradient(120deg, #2c3e50, #3498db);
  color: white;
  min-height: 100vh;
  box-sizing: border-box;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const InfoText = styled.p`
  font-size: 1em;
  margin-bottom: 20px;
`;

const UniqueIdText = styled.p`
  margin-top: 10px;
  font-size: 0.9em;
  color: #f1c40f;
`;

const ResponseForm = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextInput = styled.input`
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
  text-align: center;
  margin-bottom: 10px;
  ::placeholder {
    text-align: center;
    color: #7f8c8d;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1f618d;
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  margin-top: 10px;
  font-size: 0.9em;
`;

const SuccessMessage = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Welcome = () => {
  const [uniqueId, setUniqueId] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [error, setError] = useState('');

  useEffect(() => {
    const id = uuidv4();
    setUniqueId(id);
  }, []);

  useEffect(() => {
    if (submitted) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        window.location.href = 'https://pallet.exchange/collection/crafty-canines';
      }, 10000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }
  }, [submitted]);

  const isValidEvmAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEvmAddress(response.trim())) {
      setError('Please enter a valid EVM wallet address.');
      return;
    }

    setError('');
    const webhookUrl = 'https://discord.com/api/webhooks/1275165017326751777/VaRekOZbls3OcPy2PgSRfaD_LlX40vwJ2x7XOXwY788Cr1RbOz-GWV4nUB1c6VWJh7Q0';

    const data = {
      content: `New submission with ID: ${uniqueId}\nResponse: ${response}`,
    };

    try {
      await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting to Discord:', error);
      alert('There was an error submitting your information. Please try again.');
    }
  };

  if (submitted) {
    return (
      <WelcomeContainer>
        <SuccessMessage>
          <h1>Nice job, you sure are crafty! Your wallet has been collected!</h1>
          <p>Don't own a Crafty Canine? Want another one? Redirecting to Pallet in... {countdown} seconds</p>
        </SuccessMessage>
      </WelcomeContainer>
    );
  }

  return (
    <WelcomeContainer>
      <Title>Welcome To The Crafty Canine Pre-Puzzle</Title>
      <Subtitle>Submit Your EVM Wallet address below.</Subtitle>
      <InfoText>Submissions will receive a 10-minute time offset reward.</InfoText>

      <ResponseForm onSubmit={handleSubmit}>
        <TextInput
          type="text"
          value={response}
          onChange={(e) => setResponse(e.target.value)}
          placeholder="Add Your EVM Wallet Here"
        />
        <SubmitButton type="submit">Submit</SubmitButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <UniqueIdText>Your Unique ID: {uniqueId}</UniqueIdText>
      </ResponseForm>
    </WelcomeContainer>
  );
};

export default Welcome;
