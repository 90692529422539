import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './styles.css';
import Welcome from './Welcome';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const hoverEffect = keyframes`
  from {
    transform: scale(1);
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
  }
  to {
    transform: scale(1.1);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7);
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0; 
  background: linear-gradient(120deg, #3498db, #8e44ad);
  color: white;
  min-height: 100vh;
  box-sizing: border-box;
  animation: ${fadeIn} 1s ease-in-out;
`;

const PuzzleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 50px;
`;

const Box = styled.div`
  background-color: #282c34;
  border-radius: 15px;
  padding: 60px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: ${fadeIn} 1s ease-in-out;

  &:hover {
    animation: ${hoverEffect} 0.3s ease-in-out forwards;
  }
`;

const correctPattern = [2, 1, 0, 3, 6, 9, 10, 11];

const App = () => {
  const [hoveredBoxes, setHoveredBoxes] = useState([]);
  const navigate = useNavigate();

  const handleHover = (index) => {
    console.log(`Box ${index} hovered`);
    if (hoveredBoxes.length >= correctPattern.length) {
      console.log("Pattern length exceeded, resetting");
      setHoveredBoxes([]);
      return;
    }

    const expectedIndex = correctPattern[hoveredBoxes.length];
    if (index !== expectedIndex) {
      console.log(`Incorrect box hovered, expected ${expectedIndex}, resetting`);
      setHoveredBoxes([]); // Reset on incorrect pattern
      return;
    }

    const newHoveredBoxes = [...hoveredBoxes, index];
    setHoveredBoxes(newHoveredBoxes);
    console.log("Hovered boxes so far:", newHoveredBoxes);

    if (newHoveredBoxes.length === correctPattern.length) {
      console.log("Correct pattern detected");
      navigate('/welcome');
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeContainer>
            <PuzzleContainer>
              {Array.from({ length: 12 }).map((_, index) => (
                <Box key={index} onMouseEnter={() => handleHover(index)} />
              ))}
            </PuzzleContainer>
          </HomeContainer>
        }
      />
      <Route path="/welcome" element={<Welcome />} />
    </Routes>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
